<template>
  <div class="page-container">
    <b-table-simple
      hover
      small
      caption-top
      responsive
      class="table-simple"
    >
      <b-thead>
        <b-tr>
          <b-th
            colspan="1"
            rowspan="4"
          >
            TÊN CHỈ TIÊU THỐNG KÊ
          </b-th>
          <b-th

            colspan="11"
          >
            PHÂN LOẠI TNLĐ THEO MỨC ĐỘ THƯƠNG TẬT
          </b-th>
          <b-th

            colspan="6"
          >
            THIỆT HẠI DO TNLĐ
          </b-th>
        </b-tr>
        <b-tr>
          <b-th

            colspan="3"
          >
            SỐ VỤ (VỤ)
          </b-th>
          <b-th

            colspan="8"
          >
            SỐ NGƯỜI BỊ NẠN (NGƯỜI)
          </b-th>
          <b-th
            colspan="1"
            rowspan="3"
          >
            TỔNG SỐ NGÀY NGHỈ VÌ TAI NẠN LAO ĐỘNG
          </b-th>
          <b-th

            colspan="4"
          >
            CHI PHÍ TÍNH BẰNG TIỀN (1.000Đ)
          </b-th>
          <b-th
            colspan="1"
            rowspan="3"
          >
            THIỆT HẠI TÀI SẢN (1.000Đ)
          </b-th>
        </b-tr>
        <b-tr>
          <b-th
            colspan="1"
            rowspan="2"
          >
            TỔNG SỐ
          </b-th>
          <b-th
            colspan="1"
            rowspan="2"
          >
            SỐ VỤ CÓ NGƯỜI CHẾT
          </b-th>
          <b-th
            colspan="1"
            rowspan="2"
          >
            SỐ VỤ CÓ 2 NGƯỜI BỊ NẠN TRỞ LÊN
          </b-th>
          <b-th

            colspan="2"
          >
            TỔNG SỐ
          </b-th>
          <b-th

            colspan="2"
          >
            SỐ LAO ĐỘNG NỮ
          </b-th>
          <b-th

            colspan="2"
          >
            SỐ NGƯỜI CHẾT
          </b-th>
          <b-th

            colspan="2"
          >
            SỐ NGƯỜI BỊ THƯƠNG NẶNG
          </b-th>
          <b-th
            colspan="1"
            rowspan="2"
          >
            TỔNG SỐ
          </b-th>
          <b-th

            colspan="3"
          >
            KHOẢN CHI CỤ THỂ CỦA CƠ SỞ
          </b-th>

        </b-tr>
        <b-tr>

          <b-th

            colspan="1"
          >
            TỔNG SỐ
          </b-th>
          <b-th

            colspan="1"
          >
            NẠN NHÂN KHÔNG THUỘC QUYỀN QUẢN LÝ
          </b-th>
          <b-th

            colspan="1"
          >
            TỔNG SỐ
          </b-th>
          <b-th

            colspan="1"
          >
            NẠN NHÂN KHÔNG THUỘC QUYỀN QUẢN LÝ
          </b-th>
          <b-th

            colspan="1"
          >
            TỔNG SỐ
          </b-th>
          <b-th

            colspan="1"
          >
            NẠN NHÂN KHÔNG THUỘC QUYỀN QUẢN LÝ
          </b-th>
          <b-th

            colspan="1"
          >
            TỔNG SỐ
          </b-th>
          <b-th

            colspan="1"
          >
            NẠN NHÂN KHÔNG THUỘC QUYỀN QUẢN LÝ
          </b-th>

          <b-th

            colspan="1"
          >
            Y TẾ
          </b-th>
          <b-th

            colspan="1"
          >
            TRẢ LƯƠNG TRONG THỜI GIAN/ĐIỀU TRỊ
          </b-th>
          <b-th

            colspan="1"
          >
            BỒI THƯỜNG/ TRỢ CẤP
          </b-th>

        </b-tr>
      </b-thead>

      <b-tbody>
        <!-- <b-tr
          v-for="(item, index) in dataList"
          :key="index"
        >
          <b-td class="pb-1 pt-1">
            {{ item.name }}
          </b-td>
          <b-td
            v-for="key in listAccidentKey"
            :key="key"
            class="pb-1 pt-1"
          >
            <b-form-input
              :id="`focus-inputs-${key}-${index}`"
              :value="item[key]"
              @focus="focusValueChange(key, index)"
              @change="onChangeAmountAccident($event, key, index)"
            />
          </b-td>
        </b-tr> -->
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BThead,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BThead,
    BFormInput,
  },
  data() {
    return {
      listColumn: [
        {
          name: 'TÊN CHỈ TIÊU THỐNG KÊ',
          items: [],
        },
        {
          name: 'PHÂN LOẠI TNLĐ THEO MỨC ĐỘ THƯƠNG TẬT',
          items: [],
        },
        {
          name: 'THIỆT HẠI DO TNLĐ',
          items: [],
        },
        {
          name: 'SỐ VỤ (VỤ)',
          items: [],
        },
      ],
    }
  },
}
</script>

<style lang="scss">

</style>
